.App {
  /* text-align: center; */
  color: black;
}

legend {
  color: black !important;
  /* font-weight: bold; */
  line-height: 1.6 !important;
  font-size: 16px !important;
}
